import cc from 'classcat'
import IconArrow from 'assets/icons/arrow-payment.svg'
import {
  ReactNode,
  forwardRef,
  useEffect,
  useState,
  DetailedHTMLProps,
  SelectHTMLAttributes,
} from 'react'

type Props = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  children: ReactNode
  subtext?: string
  subtextAlign?: 'left' | 'center' | 'right'
  error?: string
  label?: string
  $disableLabel?: boolean
  placeholder?: string
  $size?: 'sm' | 'md' | 'lg'
  $type?: 'field' | 'normal'
}

// eslint-disable-next-line react/display-name
const FormDropDown = forwardRef<any, any>(
  (
    {
      children,
      placeholder,
      subtext,
      subtextAlign,
      error,
      label,
      $disableLabel,
      $type,
      $size,
      ...nativeAttrs
    }: Props,
    ref,
  ) => {
    const [element, setElement] = useState<any>(null)
    const [hasValue, setHasValue] = useState(false)

    useEffect(() => {
      setHasValue(!!element?.value)
    }, [element])

    return (
      <>
        <div className="relative">
          {!$disableLabel && hasValue && (
            <label
              htmlFor={nativeAttrs.name}
              className="text-gray-700 text-xs absolute bottom-full left-0"
            >
              {label || placeholder}
            </label>
          )}

          <div className="relative">
            <select
              {...nativeAttrs}
              className={cc([
                'w-full rounded-md border appearance-none',
                {
                  'p-1 text-sm': $size === 'sm',
                  'py-2 px-3': $size === 'md',
                  'py-4 px-5': $size === 'lg',
                },
                {
                  'bg-white placeholder-gray-600 border-gray-600':
                    !nativeAttrs.disabled && $type === 'field',
                  'bg-gray-400 text-gray-700 border-gray-500':
                    nativeAttrs.disabled && $type === 'field',
                  'bg-gray-100 border-none ': $type === 'normal',
                },
              ])}
              ref={(el) => {
                setElement(el)
                return (ref as any)?.(el)
              }}
              onChange={(e) => {
                setHasValue(!!e.target.value)
                nativeAttrs.onChange?.(e)
              }}
            >
              {placeholder && (
                <>
                  <option value="">{placeholder}</option>
                  <option value="">--------------</option>
                </>
              )}
              {children}
            </select>

            <IconArrow
              className={cc([
                'w-4 h-4 transform rotate-90 absolute right-4 top-1/2 -translate-y-1/2',
                $type === 'field' ? 'fill-gray-500' : undefined,
              ])}
            />
          </div>
        </div>
        {subtext && (
          <div
            className={cc([
              'text-gray-500 text-xs pt-1',
              {
                'text-left': subtextAlign === 'left',
                'text-center': subtextAlign === 'center',
                'text-right': subtextAlign === 'right',
              },
            ])}
          >
            {subtext}
          </div>
        )}
        {error && <div className="text-action-fail text-sm pt-1">{error}</div>}
      </>
    )
  },
)

FormDropDown.defaultProps = {
  $type: 'field',
  $size: 'md',
}

export default FormDropDown
